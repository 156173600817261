import { SUPPORTED_UNITS_LABEL, } from '@/modules/constructionProjects/api/position-service-interfaces';
import { ProgressBar } from '@schuettflix/react-components';
import { useTranslation } from 'react-i18next';
function getProductGenericType(category) {
    if (category.includes('SERVICE'))
        return 'service';
    if (category.includes('MATERIAL'))
        return 'material';
    return 'transport';
}
export function GenericPositionProduct({ positionProduct }) {
    const { t, i18n } = useTranslation();
    const formatNumber = (value) => {
        const locale = i18n.resolvedLanguage;
        return value.toLocaleString(locale);
    };
    return (<div className="flex flex-col gap-1">
            <span className="font-copy-sm">
                {t(`components.orderItemBlock.genericOrder.${getProductGenericType(positionProduct.category)}`)}
            </span>
            <span className="font-copy-md-strong block">{positionProduct.name}</span>
            {!!positionProduct.quota && (<ProgressBar key={positionProduct.id} min={0} max={positionProduct.quota} size="md" primary={positionProduct.fulfilledQuantity} secondary={positionProduct.reservedQuantity} before={<span className="whitespace-nowrap">
                            {formatNumber(positionProduct.fulfilledQuantity)}{' '}
                            {t(`units.${SUPPORTED_UNITS_LABEL[positionProduct.unit]}`)}
                        </span>} after={<span className="whitespace-nowrap">
                            {formatNumber(positionProduct.quota)}{' '}
                            {t(`units.${SUPPORTED_UNITS_LABEL[positionProduct.unit]}`)}
                        </span>}/>)}
        </div>);
}
