import { useTranslation } from 'react-i18next';
import { useOrganization } from '@/modules/constructionProjects/pages/Admin/ConstructionProjectAdminGenericPositionDetailsPage/components/PositionOverviewContent/hooks/useOrganization';
import { sortByProductCategory } from '@/modules/constructionProjects/pages/Admin/ConstructionProjectAdminGenericPositionDetailsPage/components/PositionOverviewContent/utils/sortByProductCategory';
import { Transactions } from '@/modules/constructionProjects/pages/shared/ConstructionProjectGenericPositionDetailsPage/components/Transactions';
function CustomerTransactions(props) {
    const { t } = useTranslation();
    const { data: organization } = useOrganization(props.organizationId);
    return (<div>
            <div className="font-copy-sm mb-1">{t('pages.genericPosition.customer')}</div>
            <div className="font-headline-md-strong mb-4 overflow-hidden text-ellipsis whitespace-nowrap">
                {organization?.name}
            </div>
            <Transactions {...props}/>
        </div>);
}
export function CustomerProductsSection(props) {
    const organizationsWithCustomerTransactions = props.organizations
        .map(org => {
        return {
            ...org,
            transactions: org.transactions
                .filter(transaction => transaction.functionalRoles.includes('CUSTOMER'))
                .sort(sortByProductCategory),
        };
    })
        .filter(org => org.transactions.length > 0);
    return (<div>
            {organizationsWithCustomerTransactions.map(customerTransactions => (<CustomerTransactions key={customerTransactions.orgId} organizationId={customerTransactions.orgId} financeSettings={customerTransactions.financeSettings} transactions={customerTransactions.transactions}/>))}
        </div>);
}
