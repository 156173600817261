import { Transactions } from '@/modules/constructionProjects/pages/shared/ConstructionProjectGenericPositionDetailsPage/components/Transactions';
import { useGenericPositionOverviewById } from '@/modules/constructionProjects/pages/Admin/ConstructionProjectAdminGenericPositionDetailsPage/components/PositionOverviewContent/hooks/useGenericPositionOverviewById';
export function PositionOverviewContent({ positionId }) {
    const { data } = useGenericPositionOverviewById(positionId);
    const organization = data?.organizations[0];
    if (!organization) {
        return null;
    }
    return <Transactions transactions={organization.transactions} financeSettings={organization.financeSettings}/>;
}
