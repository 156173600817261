<template>
    <div
        v-if="orderView"
        class="order-item-block"
        :class="{
            'cursor-default': (!isGenericOrderDetailsEnabled && orderView.isGenericOrder) || !isPlatformAdministrator,
        }"
        @click="onClick"
    >
        <div class="order-item-block__header">
            <div class="flex justify-end gap-2">
                <SfProjectsIcon v-if="orderView.showProjectIndicator" size="xs" multi-color class="whitespace-nowrap" />
                <SfTag v-if="orderView.orderStatus" class="whitespace-nowrap" :type="statusTagType">
                    {{ $t(`pages.order.orderStatus.${orderView.orderStatus}`) }}
                </SfTag>
            </div>

            <div class="order-item-block__head-info mt-6 overflow-hidden lg:mt-0">
                <div class="order-item-block__meta-item">
                    <span class="font-copy-sm text-subdued">
                        {{ $t(`components.orderItemBlock.orderNumber.${orderView.type}`) }}
                    </span>
                    <span class="font-copy-md-strong mt-2">
                        {{ orderView.number }}
                    </span>
                </div>
                <div class="order-item-block__meta-item">
                    <span class="font-copy-sm text-subdued">
                        {{ $t(`components.orderItemBlock.orderDate.${orderView.type}`) }}
                    </span>
                    <span class="font-copy-md mt-2 text-right lg:text-left">
                        {{ $d(orderView.created, 'short') }}
                    </span>
                </div>
                <template v-if="$root.isDesktop">
                    <div v-if="orderView.hasFulfilmentRange" class="order-item-block__meta-item">
                        <span class="font-copy-sm text-subdued">
                            {{ orderView.fulfilmentRangeLabel }}
                        </span>
                        <span class="font-copy-md mt-2 text-right lg:text-left">
                            {{ orderView.fulfilmentRange }}
                        </span>
                    </div>
                    <div v-if="orderView.showExpireDate" class="order-item-block__meta-item">
                        <span class="font-copy-sm text-subdued">
                            {{ $t('components.orderItemBlock.validUntilTitle') }}
                        </span>
                        <span class="font-copy-md mt-2">
                            {{ $d(orderView.expireDate, 'short') }}
                        </span>
                    </div>

                    <template v-if="$can('seePrices')">
                        <div v-if="orderView.totalGrossRetailPrice" class="order-item-block__meta-item">
                            <span class="font-copy-sm text-subdued">
                                {{ $t('pages.order.platformOrderList.tableHeadings.totalGross') }}
                            </span>
                            <span class="font-copy-md mt-2 text-right lg:text-left">
                                {{ $n(orderView.totalGrossRetailPrice, 'currency') }}
                            </span>
                        </div>
                        <div v-if="orderView.invoiceGrossTotal" class="order-item-block__meta-item">
                            <span class="font-copy-sm text-subdued">
                                {{ $t('pages.order.platformOrderList.tableHeadings.totalInvoicePrice') }}
                            </span>
                            <span class="font-copy-md mt-2 text-right lg:text-left">
                                {{ $n(orderView.invoiceGrossTotal, 'currency') }}
                            </span>
                        </div>
                        <div v-if="orderView.invoiceStatus" class="order-item-block__meta-item">
                            <span class="font-copy-sm text-subdued">
                                {{ $t('pages.order.platformOrderList.tableHeadings.invoiceStatus') }}
                            </span>
                            <StatusText
                                font-size="md"
                                class="mt-2 text-right lg:text-left"
                                :text="invoiceStatus"
                                :status="orderView.invoiceStatus"
                            />
                        </div>
                    </template>
                </template>
            </div>
        </div>

        <HorizontalDivider size="6" />

        <template v-if="!$root.isDesktop && orderView.hasFulfilmentRange">
            <div class="order-item-block__meta-item">
                <span class="font-copy-sm text-subdued">
                    {{ orderView.fulfilmentRangeLabel }}
                </span>
                <span class="font-copy-md mt-2">
                    {{ orderView.fulfilmentRange }}
                </span>
            </div>
            <HorizontalDivider size="6" />
        </template>

        <template v-if="orderView.isShipment">
            <div class="flex flex-wrap items-center">
                <SfVehicleIcon multi-color size="xs" class="mr-2" />
                <span class="font-copy-md-strong mr-2">
                    {{ orderView.totalTransportAmount }} &times;
                    {{ orderView.vehicleName }}
                </span>
                <div class="flex items-center truncate">
                    <span v-if="orderView.freightDescription" class="font-copy-md mr-1">
                        {{ orderView.freightDescription }}
                    </span>
                    <SfTag
                        v-if="orderView.freightType"
                        :theme="isFreightTypeHazardousWaste ? 'dark' : 'light'"
                        :type="isFreightTypeHazardousWaste ? 'error' : 'default'"
                        :subdued="isFreightTypeHazardousWaste"
                        :outline="!isFreightTypeHazardousWaste"
                        class="order-6 ml-2 whitespace-nowrap"
                    >
                        <SfWarningIcon v-if="isFreightTypeHazardousWaste" size="xxs" class="mr-1" theme="light" />
                        {{ freightTypes }}
                    </SfTag>
                </div>
                <SfLink v-if="showQuickLink" class="ml-auto whitespace-nowrap" bold @click.stop="onQuickLinkClick">
                    {{ quickLinkLabel }}
                </SfLink>
            </div>

            <HorizontalDivider size="6" />

            <div
                class="flex gap-6"
                :class="{
                    'sm-max:flex-col': isPlatform && orderView.isShipment,
                }"
            >
                <div v-if="isPlatform && orderView.client">
                    <span class="font-copy-sm text-subdued">
                        {{ $t('components.orderItemBlock.customerTitle') }}
                    </span>
                    <div>
                        <span class="font-copy-md-strong mt-2">
                            {{ orderView.client?.name }}
                        </span>
                    </div>
                </div>
                <div v-if="isPlatform && orderView.client" class="border-b md:border-r" />
                <div>
                    <span class="font-copy-sm text-subdued">
                        {{ $t('components.orderItemBlock.loadingTitle') }}
                    </span>
                    <div v-if="orderView.loading && orderView.loading?.name" class="mt-2">
                        <span class="font-copy-md-strong">
                            {{ orderView.loading?.name }}
                        </span>
                        <FormattedAddress small :address="orderView.loading.address" class="mt-1" />
                    </div>
                    <div
                        v-else-if="!orderView.loading?.name && orderView.loading.address.additionalAddress"
                        class="mt-2"
                    >
                        <span class="font-copy-md-strong">
                            {{ orderView.loading.address.additionalAddress }}
                        </span>
                        <FormattedAddress
                            small
                            :address="orderView.loading.address"
                            hide-additional-address
                            class="mt-1"
                        />
                    </div>
                    <div v-else class="mt-2">
                        <span class="font-copy-md-strong">
                            {{ orderView.loading.address.street }} {{ orderView.loading.address.number }}
                        </span>
                        <span class="font-copy-sm mt-1">
                            {{ orderView.loading.address.zip }} {{ orderView.loading.address.city }},
                            {{ orderView.loading.address.country }}
                        </span>
                    </div>
                </div>
                <div class="border-b md:border-r" />
                <div>
                    <span class="font-copy-sm">
                        {{ $t('components.orderItemBlock.unloadingTitle') }}
                    </span>
                    <div v-if="orderView.unloading?.name" class="mt-2">
                        <span class="font-copy-md-strong">
                            {{ orderView.unloading?.name }}
                        </span>
                        <FormattedAddress small :address="orderView.unloading.address" class="mt-1" />
                    </div>
                    <div
                        v-else-if="!orderView.unloading?.name && orderView.unloading.address.additionalAddress"
                        class="mt-2"
                    >
                        <span class="font-copy-sm-strong">{{ orderView.unloading.address.additionalAddress }}</span>
                        <FormattedAddress
                            small
                            :address="orderView.unloading.address"
                            hide-additional-address
                            class="mt-1"
                        />
                    </div>
                    <div v-else class="mt-2">
                        <span class="font-copy-md-strong mt-1">
                            {{ orderView.unloading.address.street }} {{ orderView.unloading.address.number }}
                        </span>
                        <span class="font-copy-sm mt-1">
                            {{ orderView.unloading.address.zip }} {{ orderView.unloading.address.city }},
                            {{ orderView.unloading.address.country }}
                        </span>
                    </div>
                </div>

                <People
                    v-if="orderView.hasPeople && !isPlatform && $root.isDesktop"
                    dark-labels
                    hide-title
                    hide-message
                    :item="orderView"
                />
            </div>
        </template>

        <template v-if="isOrderType">
            <div class="flex flex-col items-start gap-4 md:flex-row md:items-center">
                <div class="flex items-center truncate">
                    <component :is="productIconName" multi-color size="xs" class="order-1 min-w-[24px]" />
                    <span class="font-copy-md-strong order-2 ml-2">
                        {{ formattedOrderQuantity }}
                    </span>
                    <span class="font-copy-md order-3 ml-2 truncate whitespace-normal md:whitespace-nowrap">
                        {{ orderView.productName }}
                    </span>
                </div>
                <div v-if="orderView.isDisposal" class="mt-6 flex justify-start md:mt-0">
                    <SfTag v-if="orderView.product.variantDisposal" class="order-5 mr-2 whitespace-nowrap">
                        {{ orderView.product.variantDisposal.pollutionType }}
                    </SfTag>
                    <SfTag
                        v-if="orderView.product.isHazardousWaste"
                        theme="dark"
                        type="error"
                        subdued
                        :action="false"
                        class="order-6 whitespace-nowrap"
                    >
                        <SfWarningIcon size="xxs" class="mr-1" theme="light" />
                        {{ $t('pages.project.position.view.freightType.waste_dangerous') }}
                    </SfTag>
                </div>

                <SfLink
                    v-if="showQuickLink"
                    class="order-7 ml-auto whitespace-nowrap"
                    bold
                    @click.stop="onQuickLinkClick"
                >
                    {{ quickLinkLabel }}
                </SfLink>
            </div>
            <HorizontalDivider size="6" />

            <!--ORDER FOOTER-->
            <div class="flex flex-col gap-6 whitespace-normal break-words md:flex-row">
                <div v-if="isPlatform && orderView.client" class="order-item-block__meta-item">
                    <span class="font-copy-sm text-subdued">
                        {{ $t('components.orderItemBlock.customerTitle') }}
                    </span>
                    <span class="font-copy-md-strong mt-2">
                        {{ orderView.client?.name }}
                    </span>
                    <FormattedAddress
                        v-if="orderView.isGenericOrder"
                        small
                        :address="orderView.constructionSite.address"
                        class=""
                    />
                </div>

                <!-- GENERIC ORDER FOOTER -->
                <template v-if="orderView.isGenericOrder">
                    <!-- MATERIAL -->
                    <template v-if="orderView.primaryCompanyNameMap.material">
                        <div class="border-b border-r" />
                        <div>
                            <span class="font-copy-sm text-subdued">
                                {{ $t('components.orderItemBlock.genericOrder.material') }}
                            </span>
                            <span class="font-copy-md-strong mt-2">{{ orderView.primaryCompanyNameMap.material }}</span>
                        </div>
                    </template>

                    <!-- TRANSPORT -->
                    <template v-if="orderView.primaryCompanyNameMap.transport">
                        <div class="border-b border-r" />
                        <div>
                            <span class="font-copy-sm text-subdued">
                                {{ $t('components.orderItemBlock.genericOrder.transport') }}
                            </span>
                            <span class="font-copy-md-strong mt-2">{{
                                orderView.primaryCompanyNameMap.transport
                            }}</span>
                        </div>
                    </template>

                    <!-- SERVICE -->
                    <template v-if="orderView.primaryCompanyNameMap.service">
                        <div class="border-b border-r" />
                        <div>
                            <span class="font-copy-sm text-subdued">
                                {{ $t('components.orderItemBlock.genericOrder.service') }}
                            </span>
                            <span class="font-copy-md-strong mt-2">{{ orderView.primaryCompanyNameMap.service }}</span>
                        </div>
                    </template>
                </template>

                <!-- LEGACY ORDER FOOTER -->
                <template v-if="!orderView.isGenericOrder">
                    <div v-if="isPlatform && orderView.client" class="border-b md:border-r" />
                    <div>
                        <span v-if="orderView.isDisposal" class="font-copy-sm text-subdued">
                            {{ $t('components.orderItemBlock.loadingTitle') }}
                        </span>
                        <span v-else class="font-copy-sm text-subdued">
                            {{ $t('components.orderItemBlock.constructionTitle') }}
                        </span>
                        <span class="font-copy-md-strong mt-2">
                            {{ orderView.constructionSite?.name }}
                        </span>
                        <FormattedAddress small :address="orderView.constructionSite.address" class="mt-1" />
                    </div>
                    <div class="border-b border-r" />
                    <div>
                        <div v-if="!orderView.unloading">
                            <span class="font-copy-sm text-subdued">
                                {{ $t('components.orderItemBlock.supplier') }}
                            </span>
                            <span class="font-copy-md-strong mt-2">
                                {{ orderView.supplier?.name }}
                            </span>
                            <span v-if="orderView.loading && orderView.loading.address">
                                <FormattedAddress small :address="orderView.loading.address" class="mt-1" />
                            </span>
                        </div>
                        <div v-else>
                            <span class="font-copy-sm text-subdued">
                                {{ $t('components.orderItemBlock.unloadingTitle') }}
                            </span>
                            <span class="font-copy-md-strong mt-2">
                                {{ orderView.supplier?.name }}
                            </span>
                            <FormattedAddress
                                small
                                :address="orderView.unloading.address"
                                hide-additional-address
                                class="mt-1"
                            />
                        </div>
                    </div>
                </template>
                <People
                    v-if="orderView.hasPeople && !isPlatform && $root.isDesktop"
                    dark-labels
                    hide-title
                    hide-message
                    :item="orderView"
                />
            </div>
        </template>

        <!-- FOOTER -->

        <template v-if="!$root.isDesktop">
            <div class="my-6 border-t" />
            <div class="flex">
                <People
                    v-if="orderView.hasPeople"
                    hide-title
                    dark-labels
                    hide-message
                    :item="orderView"
                    class="flex-1"
                />

                <div
                    v-else-if="orderView.expireDate"
                    class="order-item-block__meta-item order-item-block__meta-item--footer"
                >
                    <span class="font-copy-sm">
                        {{ $t('components.orderItemBlock.validUntilTitle') }}
                    </span>
                    <span class="font-copy-sm-strong mt-1">
                        {{ $d(orderView.expireDate, 'short') }}
                    </span>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import routeContext from '@/plugins/mixins/routeContext';
import { CONTEXT_PLATFORM } from '@/constants/context';
import FormattedAddress from '@/components/FormattedAddress';
import HorizontalDivider from '@/_components/HorizontalDivider/HorizontalDivider.vue';
import People from '@/components/Order/People';
import { ORDER_LINE_ITEM_GROUP_TYPE } from '@/constants/orderLineItemGroupTypes';
import OrderItemBlockView from '@/components/List/OrderItemBlockView';

import {
    SfLink,
    SfTag,
    SfMaterialIcon,
    SfProjectsIcon,
    SfWarningIcon,
    SfVehicleIcon,
    SfLaptopIcon,
} from '@schuettflix/vue-components';
import StatusText from '@/_components/StatusText/StatusText';
import { mapGetters } from 'vuex';
import { useLd } from '@/services/LaunchDarkly';

export default {
    name: 'OrderItemBlock',

    components: {
        StatusText,
        FormattedAddress,
        HorizontalDivider,
        People,

        SfTag,
        SfLink,
        SfMaterialIcon,
        SfVehicleIcon,
        SfLaptopIcon,
        SfProjectsIcon,
        SfWarningIcon,
    },

    mixins: [routeContext],

    props: {
        orderView: {
            type: OrderItemBlockView,
            required: true,
        },
    },

    setup() {
        const isGenericOrderDetailsEnabled = useLd('monolith-display-generic-order-details');

        return {
            isGenericOrderDetailsEnabled,
        };
    },

    computed: {
        ...mapGetters('platform', ['genericOrderUrl']),
        ...mapGetters('user', ['isPlatformAdministrator']),

        isPlatform() {
            return this.inRouteContext(CONTEXT_PLATFORM);
        },

        showFooter() {
            return (this.orderView.expireDate || this.orderView.hasPeople) && !this.$root.isDesktop;
        },
        statusTagType() {
            return this.orderView.isStatusWarning ? 'warning' : this.orderView.isStatusDanger ? 'error' : 'positive';
        },
        isOrderType() {
            return (
                this.orderView.isDelivery ||
                this.orderView.isPickup ||
                this.orderView.isDisposal ||
                this.orderView.isGenericOrder
            );
        },
        orderType() {
            return this.$t(
                {
                    [ORDER_LINE_ITEM_GROUP_TYPE.DISPOSAL_HAZARDOUS_WASTE]:
                        'pages.order.orderType.transport-dangerous-waste',
                    [ORDER_LINE_ITEM_GROUP_TYPE.DISPOSAL_NON_HAZARDOUS_WASTE]: 'pages.order.orderType.transport-waste',
                    [ORDER_LINE_ITEM_GROUP_TYPE.DELIVERY]: 'pages.order.orderType.transport-delivery',
                    'transport-disposal': 'pages.order.orderType.transport-disposal',
                    [ORDER_LINE_ITEM_GROUP_TYPE.PICKUP]: 'pages.order.orderType.transport-pickup',
                    [ORDER_LINE_ITEM_GROUP_TYPE.SHIPMENT]: 'pages.order.orderType.transport-shipment',
                }[this.orderView.orderType]
            );
        },
        invoiceStatus() {
            return this.$t(
                {
                    booked: 'status.invoice.booked',
                    cancelled: 'status.invoice.cancelled',
                    created: 'status.invoice.created',
                    paid: 'status.invoice.paid',
                }[this.orderView.invoiceStatus]
            );
        },
        freightTypes() {
            return this.$t(
                {
                    material: 'productTypes.tags.material',
                    waste_dangerous: 'productTypes.tags.waste_dangerous',
                    waste_non_dangerous: 'productTypes.tags.waste_non_dangerous',
                    rc_material: 'productTypes.tags.rc_material',
                }[this.orderView.freightType]
            );
        },
        formattedOrderQuantity() {
            const qty = this.orderView.qty;
            return `${this.$n(qty, 'qty')} ${this.$tc(this.orderView.qtyUnitTranslationKey, qty)}`;
        },
        isFreightTypeHazardousWaste() {
            return this.orderView.freightType === 'waste_dangerous';
        },
        productIconName() {
            const productIconNameMap = {
                material: 'SfMaterialIcon',
                transport: 'SfVehicleIcon',
                service: 'SfLaptopIcon',
            };
            return productIconNameMap[this.orderView.productIcon] || 'SfMaterialIcon';
        },
        quickLinkLabel() {
            return this.orderView.isGenericOrder
                ? this.$t('pages.order.platformOrderList.action.showFulfillmentPage')
                : this.$t('pages.order.platformOrderList.action.showTransports');
        },
        showQuickLink() {
            if (this.orderView.isGenericOrder && this.orderView.orderStatus !== 'new') {
                return false;
            }
            return this.isPlatformAdministrator && this.$root.isDesktop;
        },
    },

    methods: {
        onQuickLinkClick() {
            if (this.orderView.isGenericOrder) {
                this.showGenericOrderFulfillmentPage();
            } else {
                this.showTransports(this.orderView.id);
            }
        },
        showTransports(orderId) {
            this.$router
                .push({
                    name: this.$root.findRouteName('order-transports'),
                    params: {
                        ...this.$route.params,
                        orderId,
                    },
                    query: this.$route.query,
                })
                .catch(() => {});
        },
        showGenericOrderFulfillmentPage() {
            const genericOrderId = this.orderView.genericOrderId;
            window.open(`${this.genericOrderUrl}/fulfillment/${genericOrderId}`, '_self');
        },
        onClick() {
            this.$emit('click');
        },
    },
};
</script>

<style lang="scss">
.order-item-block {
    cursor: pointer;
    margin: 9px;

    .freight-description {
        margin-right: 5px;
        margin-bottom: 10px;
        display: inline-block;
    }

    .freight-type-tag {
        margin-left: 0;
    }
}

.order-item-block__header {
    @media screen and (min-width: $layout-desktop-min) {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
    }
}

.order-item-block__head-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media screen and (min-width: $layout-desktop-min) {
        .order-item-block__meta-item:nth-of-type(n + 2) {
            margin-left: 20px;
            padding-left: 15px;
            border-left: 1px solid $color-mediumGrey;
        }
    }
}

.order-item-block__address-info {
    .order-item-block__meta-item:nth-of-type(n + 2) {
        margin-top: 15px;
    }

    @media screen and (min-width: $layout-desktop-min) {
        display: flex;
        flex-direction: row;

        .order-item-block__meta-item {
            flex: 1;
        }

        .order-item-block__meta-item:nth-of-type(n + 2) {
            margin-top: 0;
            margin-left: 20px;
            padding-left: 15px;
            border-left: 1px solid $color-mediumGrey;
        }
    }
}

.order-item-block__header-tag {
    display: grid;
    justify-content: flex-end;
    margin-bottom: 10px;
    grid-auto-flow: column;
    gap: 15px;
}

.order-view__people {
    @media screen and (min-width: $layout-desktop-min) {
        padding-left: 15px;
        border-left: 1px solid $color-mediumGrey;

        .people__items--right {
            margin-left: 20px;
        }
    }
}

.order-item-block__meta-item--footer {
    margin-left: 20px;
    padding-left: 15px;
    border-left: 1px solid $color-mediumGrey;
}
</style>
